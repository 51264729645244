<template>
  <div v-show="homeProfileLoaded" class="home-main">
    <Suspense>
      <HomeProfile @edit-profile="$emit('edit-profile')" @homeProfileLoaded="homeProfileLoaded = true" :reRender="reRender" />
    </Suspense>
    <Suspense>
      <HomeWish @homeWishLoaded="homeWishLoaded = true" @change-target-chatter="changeTargetChatter" />
    </Suspense>
    <Suspense>
      <HomeOpp @homeOppLoaded="homeOppLoaded = true" />
    </Suspense>
  </div>
</template>

<script>
import HomeProfile from "@/components/Home/homeProfile.vue";
import HomeOpp from "@/components/Home/homeOpp.vue";
import HomeWish from "@/components/Home/homeWish";
import { ref } from "vue";

export default {
  props: ["reRender"],
  emits: ["edit-profile", "change-target-chatter"],
  components: {
    HomeWish,
    HomeProfile,
    HomeOpp,
  },
  setup(props, { emit }) {
    const homeProfileLoaded = ref(false)
    const homeOppLoaded = ref(false)
    const homeWishLoaded = ref(false)

    const changeTargetChatter = (chatterId) => {
      emit("change-target-chatter", chatterId);
    }

    return {
      homeProfileLoaded,
      homeOppLoaded,
      homeWishLoaded,
      changeTargetChatter
    }
  },
};
</script>

<style scoped>
.home-main {
  flex: 1 0 80%;
  padding: 5vh 8vh 8vh 8vh;
  animation: fadein 1s;
  background-color: var(--background);
}

@media screen and (max-width: 600px) {
  .home-main {
    padding: 0 0;
  }
}
</style>
